
export default {
    components: {
    },
    props: {
        brandConfig: {
            type: Object,
            default: () => {
                return {};
            },
        },
        country: {
            type: String,
            default: "",
        },
        storeId: {
            type: String,
            default: "",
        },
        storeName: {
            type: String,
            default: "",
        },
    },
    created() {
    },
    mounted() {
    },
    data() {
        return {
        };
    },
    watch: {

    },
    methods: {

    }
}